import gql from 'graphql-tag'

export const GET_TIME_ENTRIES = gql`
  query($startISO: String!, $endISO: String!) {
    getTimeEntries(startISO: $startISO, endISO: $endISO) {
      _id
      billable
      end
      originalComment
      comment
      originalDurationInHours
      durationInHours
      originalEntryDate
      originalPersonId
      originalProjectId
      projectId
      referenceId
      referenceType
      repliconTimesheetId
      source
      sourceId
      start
      quickbooksInvoiceId
      creationWay
      eventTagId
      approveState {
        status
        rejectionReason
        modifiedBy
      }
    }
  }
`

export const GET_TIME_ENTRIES_AS_DELEGATE = gql`
  query($startISO: String!, $endISO: String!, $delegateId: String!) {
    getTimeEntries_asDelegate(startISO: $startISO, endISO: $endISO, delegateId: $delegateId) {
      _id
      billable
      end
      originalComment
      originalDurationInHours
      originalEntryDate
      originalPersonId
      originalProjectId
      referenceId
      referenceType
      repliconTimesheetId
      projectId
      source
      sourceId
      start
      quickbooksInvoiceId
      creationWay
      eventTagId
      approveState {
        status
        rejectionReason
        modifiedBy
      }
    }
  }
`

export const UPDATE_TIME_ENTRY = gql`
  mutation(
    $_id: String!
    $billable: String
    $end: String
    $originalComment: String
    $originalDurationInHours: Float
    $originalEntryDate: String
    $originalPersonId: String
    $originalProjectId: String
    $referenceId: String
    $referenceType: String
    $repliconTimesheetId: String
    $sourceId: String
    $start: String
    $eventTagId: String
    $approveState: ApproveStateInput
    $comment: String
    $durationInHours: Float
  ) {
    updateTimeEntry(
      _id: $_id
      billable: $billable
      end: $end
      originalComment: $originalComment
      originalDurationInHours: $originalDurationInHours
      originalEntryDate: $originalEntryDate
      originalPersonId: $originalPersonId
      originalProjectId: $originalProjectId
      referenceId: $referenceId
      referenceType: $referenceType
      repliconTimesheetId: $repliconTimesheetId
      sourceId: $sourceId
      start: $start
      eventTagId: $eventTagId
      approveState: $approveState
      comment: $comment
      durationInHours: $durationInHours
    ) {
      _id
      billable
      end
      originalComment
      comment
      originalDurationInHours
      durationInHours
      originalEntryDate
      originalPersonId
      originalProjectId
      referenceId
      referenceType
      repliconTimesheetId
      source
      sourceId
      start
      quickbooksInvoiceId
      creationWay
      eventTagId
      projectId
      personId
      approveState {
        status
        rejectionReason
        modifiedBy
      }
    }
  }
`

export const UPDATE_TIME_ENTRY_AS_DELEGATE = gql`
  mutation(
    $delegateId: String!
    $_id: String!
    $billable: String
    $end: String
    $originalComment: String
    $originalDurationInHours: Float
    $originalEntryDate: String
    $originalPersonId: String
    $originalProjectId: String
    $referenceId: String
    $referenceType: String
    $repliconTimesheetId: String
    $sourceId: String
    $start: String
    $eventTagId: String
  ) {
    updateTimeEntry_asDelegate(
      delegateId: $delegateId
      _id: $_id
      billable: $billable
      end: $end
      originalComment: $originalComment
      originalDurationInHours: $originalDurationInHours
      originalEntryDate: $originalEntryDate
      originalPersonId: $originalPersonId
      originalProjectId: $originalProjectId
      referenceId: $referenceId
      referenceType: $referenceType
      repliconTimesheetId: $repliconTimesheetId
      sourceId: $sourceId
      start: $start
      eventTagId: $eventTagId
    ) {
      _id
      billable
      end
      originalComment
      originalDurationInHours
      originalEntryDate
      originalPersonId
      originalProjectId
      referenceId
      referenceType
      repliconTimesheetId
      source
      sourceId
      projectId
      personId
      start
      quickbooksInvoiceId
      creationWay
      eventTagId
      approveState {
        status
        rejectionReason
        modifiedBy
      }
    }
  }
`

export const DELETE_TIME_ENTRY = gql`
  mutation($_id: String!) {
    deleteTimeEntry(_id: $_id)
  }
`
export const DELETE_TIME_ENTRY_AS_DELEGATE = gql`
  mutation($_id: String!, $delegateId: String!) {
    deleteTimeEntry_asDelegate(_id: $_id, delegateId: $delegateId)
  }
`

export const CREATE_TIME_ENTRY = gql`
  mutation(
    $billable: String
    $end: String
    $originalComment: String
    $originalDurationInHours: Float
    $originalEntryDate: String
    $originalProjectId: String
    $referenceId: String
    $referenceType: String
    $repliconTimesheetId: String
    $sourceId: String
    $start: String
    $creationWay: String
    $eventTagId: String
  ) {
    createTimeEntry(
      billable: $billable
      end: $end
      originalComment: $originalComment
      originalDurationInHours: $originalDurationInHours
      originalEntryDate: $originalEntryDate
      originalProjectId: $originalProjectId
      referenceId: $referenceId
      referenceType: $referenceType
      repliconTimesheetId: $repliconTimesheetId
      sourceId: $sourceId
      start: $start
      creationWay: $creationWay
      eventTagId: $eventTagId
    ) {
      _id
      billable
      end
      originalComment
      originalDurationInHours
      originalEntryDate
      originalPersonId
      originalProjectId
      referenceId
      referenceType
      repliconTimesheetId
      source
      sourceId
      start
      quickbooksInvoiceId
      creationWay
      eventTagId
    }
  }
`

export const CREATE_TIME_ENTRY_AS_DELEGATE = gql`
  mutation(
    $delegateId: String!
    $billable: String
    $end: String
    $originalComment: String
    $originalDurationInHours: Float
    $originalEntryDate: String
    $originalProjectId: String
    $referenceId: String
    $referenceType: String
    $repliconTimesheetId: String
    $sourceId: String
    $start: String
    $creationWay: String
    $eventTagId: String
  ) {
    createTimeEntry_asDelegate(
      delegateId: $delegateId
      billable: $billable
      end: $end
      originalComment: $originalComment
      originalDurationInHours: $originalDurationInHours
      originalEntryDate: $originalEntryDate
      originalProjectId: $originalProjectId
      referenceId: $referenceId
      referenceType: $referenceType
      repliconTimesheetId: $repliconTimesheetId
      sourceId: $sourceId
      start: $start
      creationWay: $creationWay
      eventTagId: $eventTagId
    ) {
      _id
      billable
      end
      originalComment
      originalDurationInHours
      originalEntryDate
      originalPersonId
      originalProjectId
      referenceId
      referenceType
      repliconTimesheetId
      source
      sourceId
      start
      quickbooksInvoiceId
      creationWay
      eventTagId
    }
  }
`

export const GET_TIME_ENTRIES_BY_PROJECT = gql`
  query getTimeEntriesByProjectIds(
    $projectIds: [String!]!
    $repliconTimesheetId: [String]
    $approveState: String
  ) {
    getTimeEntriesByProjectIds(
      projectIds: $projectIds
      repliconTimesheetId: $repliconTimesheetId
      approveState: $approveState
    ) {
      _id
      billable
      end
      comment
      originalComment
      originalDurationInHours
      originalEntryDate
      originalPersonId
      originalProjectId
      referenceId
      referenceType
      repliconTimesheetId
      source
      sourceId
      start
      quickbooksInvoiceId
      creationWay
      eventTagId
      projectId
      personId
      durationInHours
      approveState {
        status
        rejectionReason
        modifiedBy
      }
    }
  }
`
