import React, { useMemo, useState } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import { Select, MenuItem } from '@material-ui/core'
import TimeEntry from '../../../server/models/timeEntry'
import { assureTimeEntriesExistLocally, updateTimeEntry } from '../../../entities/timeEntry/service'
import { ksTheme } from '../../../theme'
import { GeneralButton } from '../style'
import { StyledRejectedTableContainer } from './styles'

const RejectedTableRow = ({ entry, onEntryUpdate, client, projects = [] }) => {
  const [localEntry, setLocalEntry] = useState(entry)
  const handleCommentChange = e => {
    setLocalEntry({ ...localEntry, comment: e.target.value })
  }

  const handleHoursChange = e => {
    setLocalEntry({ ...localEntry, durationInHours: +e.target.value })
  }
  const onResubmitEntry = async () => {
    const updatedEntry = {
      ...localEntry,
      comment: localEntry.comment,
      durationInHours: localEntry.durationInHours,
      originalDurationInHours: localEntry.originalDurationInHours,
      approveState: {
        rejectionReason: '',
        modifiedBy: entry.approveState?.modifiedBy,
        status: 'pending',
      },
    }

    const results = await updateTimeEntry({
      client,
      timeEntry: updatedEntry,
    })
    if (results) {
      onEntryUpdate(updatedEntry)
    }
  }
  const handleBillableChange = e => {
    setLocalEntry({ ...localEntry, billable: e.target.value })
  }

  const projectName = useMemo(
    () => projects.find(project => project._id === localEntry.projectId)?.name,
    [projects, localEntry],
  )

  console.log({ projects, projectName, localEntry })
  return (
    <TableRow key={localEntry._id}>
      <TableCell>{projectName}</TableCell>
      <TableCell>{localEntry.approveState?.rejectionReason}</TableCell>
      <TableCell>
        <TextField value={localEntry.comment} onChange={handleCommentChange} />
      </TableCell>
      <TableCell>
        <TextField type="number" value={localEntry.durationInHours} onChange={handleHoursChange} />
      </TableCell>
      <TableCell>{new Date(localEntry.originalEntryDate).toLocaleDateString()}</TableCell>
      <TableCell>
        <Select value={localEntry.billable} onChange={handleBillableChange}>
          <MenuItem value="ProjectRate">Billable</MenuItem>
          <MenuItem value="NonBillable">No billable</MenuItem>
        </Select>
      </TableCell>

      <TableCell>
        <GeneralButton
          bgColor={ksTheme.colors.kellyGreen.medium}
          hoverBgColor={ksTheme.colors.kellyGreen.dark}
          onClick={onResubmitEntry}
        >
          Resubmit
        </GeneralButton>
      </TableCell>
    </TableRow>
  )
}

const RejectedTable = ({ client, projects }) => {
  const [rejectedEntries, setRejectedEntries] = React.useState([])

  React.useEffect(() => {
    const fetchEntries = async () => {
      const oneMonthAgo = new Date()
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 2)
      await assureTimeEntriesExistLocally({
        client,
        startISO: oneMonthAgo.toISOString(),
        endISO: new Date().toISOString(),
      })

      TimeEntry.toArray().then(entries => {
        const rejected = entries.filter(entry => entry.approveState?.status === 'rejected')
        setRejectedEntries(rejected)
      })
    }
    fetchEntries()
  }, [])

  if (!rejectedEntries.length) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          fontSize: 20,
        }}
      >
        No rejected entries
      </div>
    )
  }
  const onEntryUpdate = async entry => {
    setRejectedEntries(rejected => rejected.filter(e => e._id !== entry._id))
    await TimeEntry.update(entry._id, entry)
  }

  return (
    <StyledRejectedTableContainer>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Project Name</TableCell>
              <TableCell>Rejected Reason</TableCell>
              <TableCell>Comment</TableCell>
              <TableCell>Hours</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Billable</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rejectedEntries.map(entry => (
              <RejectedTableRow
                key={entry._id}
                entry={entry}
                client={client}
                onEntryUpdate={onEntryUpdate}
                projects={projects}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledRejectedTableContainer>
  )
}

RejectedTableRow.propTypes = {
  entry: PropTypes.object.isRequired,
  onEntryUpdate: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  projects: PropTypes.array,
}

RejectedTable.propTypes = {
  client: PropTypes.object.isRequired,
  projects: PropTypes.array,
}

export default RejectedTable
